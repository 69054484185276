import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./Short.css";
import useMobileBreakpoint from "../../contexts/MobileBreakpoint/useMobileBreakpoint";

import Feed from "../../components/feed";

import useStream from "../../contexts/Stream/useStream";

import { HttpUnAuthApi } from "../../interface/unauth-api";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Toast from "../../utils/Toast";
import IoTClient from "../../utils/iot/IoTClient";

import LoadingCircleNoBackdrop from "../../utils/LoadingCircleNoBackdrop";

const unauthApi = new HttpUnAuthApi();

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 72,
  height: 36,
  padding: 0,
  margin: "0px !important",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(36px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url("/images/sound_on.png")`,
        backgroundSize: "30px",
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#E9E9EA",
        opacity: 0.35,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 30,
    height: 30,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url("/images/sound_off.png")`,
      backgroundSize: "30px",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 36 / 2,
    backgroundColor: "#E9E9EA",
    boxShadow: "inset #100f0f -1px 1px 7px -3px",
    opacity: 0.35,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Short = () => {
  const { isMobileView } = useMobileBreakpoint();
  const { activeStream, setStreams } = useStream();
  const toastRef = useRef();
  const iotRef = useRef();
  const iotClientId = useRef();
  const feedRef = useRef();

  const params = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();

  const [muted, setMuted] = useState(true);
  const [objectFit, setObjectFit] = useState("full");
  const [shortInfo, setShortInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const viewControl = queryParams.control === undefined ? false : true;
  const groupVdoNo = queryParams.groupVdoNo === undefined ? "" : queryParams.groupVdoNo;
  const userVipCode = queryParams.vipCode === undefined ? "" : queryParams.vipCode;

  const [playlistLength, setPlayListLength] = useState(0);

  useEffect(() => {
    console.log("params : ", params);
    const fetchStreams = async () => {
      try {
        const param = {
          command: "get_short_info",
          short_key: params.shortSeq,
          userVipCode: userVipCode,
        };
        // console.log(param);
        // setIsLoading(true);
        const res = await unauthApi.post(param);
        // console.log("res : ", res);
        if (res.code === "200") {
          setShortInfo(res.response.short_info);
          const streams = res.response.short_info.priority_list.map((v, i) => ({
            id: v.video_key,
            stream: {
              playbackUrl: v.playback_url,
            },
            metadata: {
              sk: v.video_key,
            },
          }));

          let paramsStreamId = params.id;
          const streamIds = streams.map((s) => s.id);

          // 2024년 5월 23일 추가
          // 클릭영상 제일 위에 추가 + 우선순위 고정영상 + 셔플
          let initialStreamIndex = streamIds.indexOf(paramsStreamId); // 기존 stream list에서 클릭영상 index 찾기
          if (initialStreamIndex < 0) {
            paramsStreamId = streamIds[0];
            initialStreamIndex = 0;
          }

          const initialStream = streams[initialStreamIndex]; // 클릭영상 스트림 정보 메모리 저장
          streams.splice(initialStreamIndex, 1); // 기존 stream list에서 클릭영상 삭제
          streams.unshift(initialStream); // stream list 제일 앞에 클릭영상 넣기

          const initialStreamId = streamIds.includes(paramsStreamId) ? paramsStreamId : streamIds[0];
          setPlayListLength(streamIds.length);
          setStreams(streams, initialStreamId);
          // setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchStreams();
  }, [setStreams]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the page URL for the active stream
  useEffect(() => {
    if (!activeStream) return;

    const activeStreamId = activeStream.data.id;
    if (activeStreamId !== params.id) {
      let keys = Object.keys(queryParams);
      let queryTet = "?";
      for (let i = 0; i < keys.length; i++) {
        if (i > 0) {
          queryTet = queryTet + "&";
        }
        queryTet = queryTet + keys[i] + "=" + queryParams[keys[i]];
      }
      navigate(`/short/${params.shortSeq}/${activeStreamId}${queryTet}`, { replace: true });
    }
  }, [activeStream, params.id, navigate, params.shortSeq, queryParams]);

  const closeWindow = () => {
    // toastRef.current?.toast("고객사 시스템 닫기 연동 필요", "info", 4000, {
    //   vertical: "top",
    //   horizontal: "center",
    // });
    postMsg({ msg: "closeWindow" }, "*");
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic, payload, clientId) {
    if (topic === `short/${params.shortSeq}/statistic`) {
      feedRef.current.postIot(payload);
    }
  }

  const toastMsg = (msg, serverity, sec, pos) => {
    toastRef.current.toast(msg, serverity, sec, pos);
  };

  const postMsg = (payload, target) => {
    window.parent.postMessage(payload, target);
  };

  useEffect(() => {
    if (isMobileView) setObjectFit("full");
    else setObjectFit("center");
  }, [isMobileView]);

  return (
    <>
      <div className="grid">
        <div className="feed">
          <Feed
            ref={feedRef}
            globalMuted={muted}
            objectFit={objectFit}
            setObjectFit={setObjectFit}
            viewControl={viewControl}
            shortInfo={shortInfo}
            iotClientId={iotClientId.current}
            postMsg={postMsg}
            toastMsg={toastMsg}
            setIsLoading={setIsLoading}
            groupVdoNo={queryParams.groupVdoNo}
            playlistLength={playlistLength}
          />
        </div>
        <Box sx={{ position: "absolute", top: "calc(1.5rem - 6px)", left: "calc(1rem + 11px)", zIndex: "4" }}>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={!muted}
                onChange={(event) => {
                  setMuted(!event.target.checked);
                }}
              />
            }
          />
        </Box>
        <Box sx={{ position: "absolute", top: "1.5rem", right: "20px", zIndex: "4" }}>
          <IconButton sx={{ padding: 0 }} onClick={closeWindow}>
            <Box component="img" className="thd-short-close" src="/images/icon-x.png" />
          </IconButton>
        </Box>
      </div>
      {/* <IoTClient
        ref={iotRef}
        videoSeq={params.shortSeq}
        iotRegion={process.env.REACT_APP_IOT_REGION}
        iotIdentityPollId={process.env.REACT_APP_IOT_IDENTITY_POOL_ID}
        iotEndPoint={process.env.REACT_APP_IOT_ENDPOINT}
        recieveIoTMessage={recieveIoTMessage}
        iotConnectEnd={(clientId) => {
          iotClientId.current = clientId;
          console.info("iot Connection Success : ", clientId);
        }}
        iotLost={() => {
          console.error("iot Lost >>> Reload");
          iotRef.current.connect();
        }}
      /> */}
      <LoadingCircleNoBackdrop loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default Short;
